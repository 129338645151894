<template>
  <v-combobox
    :class="comboboxClass"
    :value="time"
    :items="items"
    :label="label"
    :disabled="disabled"
    outlined
    menu-props="auto"
    :dense="dense"
    hide-details
    :error="error"
    @input="handleInput"
    :filter="filter"
    @update:search-input="validateInput"
  />
</template>

<script>
export default {
	name: "TimeSelectorV2Vue",
	props: {
		initialTime: {
			type: String,
			default: '18:00'
		},
		label: {
			type: String,
			default: 'Time'
		},
		dense: {
			type: Boolean,
			default: false
		},
		comboboxClass: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			time: this.initialTime,
			error: false
		}
	},
	watch: {
		initialTime(value) {
			this.time = value
			// this.handleInput(value)
		}
	},
	computed: {
		items() {
			const times = []
			for(let i = 0; i < 24; i++) {
				for(let j = 0; j < 4; j++) {
					let hour = String(i)
					let minutes = String(j * 15)
					if(hour.length <= 1) {
						hour = '0' + hour
					}
					if(minutes.length <= 1) {
						minutes = '0' + minutes
					}
					times.push(hour + ':' + minutes)
				}
			}
			return times
		}
	},
	methods: {
		filter(item, queryText) {
			return item.replace(":", "").includes(queryText)
		},
		validateInput(value) {
			if(typeof value === 'undefined' || value === null) {
				this.error = true
				return
			}

			let regEx = /^([0-1][0-9]|2[0-3])([0-5][0-9])$/
			if(value.includes(':')) {
				regEx = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
			}

			if(regEx.test(value) === false) {
				this.error = true
				return
			}

			this.error = false
		},
		handleInput(value) {
			this.validateInput(value)
			if(this.error === true) {
				return
			}

			if(value && value.length === 4 && !value.includes(':')) {
				this.time = value.slice(0, 2) + ":" + value.slice(2)
			} else if(value && value.length === 5 && value.includes(':')) {
				this.time = value
			}
			this.$emit('selectTime', this.time)
		}
	}
}
</script>

<style scoped>

</style>
