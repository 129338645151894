<template>
  <div
    class="add-booking-card"
    ref="add-booking-card"
    v-resize="onResize"
  >
    <v-card
      style="position: relative"
      class="pa-0"
    >
      <v-toolbar
        flat
        style="background-color: #f7f7f7"
      >
        <v-toolbar-title class="text-h5">
          <div v-if="!bookingObject || !bookingObject.uuid">
            {{ $t("addBooking") }}
          </div>
          <div
            v-else
            class="pr-2"
          >
            <div
              v-if="
                bookingObject.customer !== null &&
                  bookingObject.customer.address.name_line
              "
            >
              {{ bookingObject.customer.address.name_line }}
            </div>
            <div
              v-else
              class="font-italic red--text"
            >
              {{ $t("noName") }}
            </div>
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="typeOfBooking === 'reservation'"
          text
          outlined
          @click="sendReservation"
        >
          {{ $t("sendReservation") }}
        </v-btn>
        <v-btn
          v-if="typeOfBooking === 'event'"
          text
          outlined
          @click="sendTicket"
        >
          {{ $t("sendTicket") }}
        </v-btn>
      </v-toolbar>
      <v-card-text class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="7"
            class="pr-md-6"
          >
            <v-row>
              <v-col cols="12">
                <v-btn
                  v-if="reservationTableItem && ((!bookingObject && !bookingItem) || (bookingObject && Array.isArray(bookingObject.locationVariations) && bookingObject.locationVariations.length > 0))"
                  :depressed="typeOfBooking === 'reservation'"
                  :text="typeOfBooking !== 'reservation'"
                  :outlined="typeOfBooking === 'reservation'"
                  class="mr-3"
                  :class="
                    typeOfBooking === 'reservation' ? 'primary--text' : ''
                  "
                  style="text-transform: inherit"
                  @click="typeOfBooking = 'reservation'"
                >
                  {{ $t("tableReservation") }}
                </v-btn>
                <v-btn
                  v-if="!Array.isArray(tables) || tables.length < 1"
                  :depressed="typeOfBooking === 'event'"
                  :text="typeOfBooking !== 'event'"
                  :outlined="typeOfBooking === 'event'"
                  :class="typeOfBooking === 'event' ? 'primary--text' : ''"
                  style="text-transform: inherit"
                  @click="typeOfBooking = 'event'"
                >
                  {{ $t("event") }}
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="pt-3 pb-0"
              >
                <div
                  style="font-size: 16px; color: #6b6b6b; font-weight: 600"
                  class="d-flex align-center"
                >
                  <div>{{ $t('Customer information') }}</div>
                  <div class="flex-grow-1 ml-2">
                    <v-divider />
                  </div>
                </div>
              </v-col>
              <v-col
                class="pb-1 pr-md-2"
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >
                <v-combobox
                  hide-details
                  :disabled="processingBooking || !hasSelectedLocation"
                  type="phone"
                  :label="$t('phone')"
                  v-model="contactPhone"
                  :loading="fetchingPhone"
                  outlined
                  x-large
                  @update:search-input="filterPhone"
                  autocomplete="null"
                  :items="filteredCustomers"
                  item-text="phone"
                  item-value="phone"
                  append-icon=""
                  dense
                  :return-object="false"
                  ref="phoneComboBox"
                  @focus="updateCustomerPhoneInputFocus"
                  @blur="customerPhoneInputActive = false"
                />
              </v-col>
              <v-col
                class="pb-1 pl-md-2"
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >
                <v-combobox
                  hide-details
                  :disabled="processingBooking || !hasSelectedLocation"
                  type="email"
                  :label="$t('email')"
                  v-model="contactEmail"
                  :loading="fetchingEmail"
                  outlined
                  x-large
                  dense
                  @update:search-input="filterEmail"
                  autocomplete="null"
                  :items="filteredCustomers"
                  item-value="email"
                  item-text="email"
                  append-icon=""
                  :return-object="false"
                  ref="emailComboBox"
                  @focus="updateCustomerEmailInputFocus"
                  @blur="customerEmailInputActive = false"
                />
              </v-col>
              <v-col
                class="pb-1"
                cols="12"
              >
                <v-combobox
                  hide-details
                  :disabled="processingBooking"
                  type="text"
                  @update:search-input="filterName"
                  autocomplete="null"
                  :items="filteredCustomers"
                  item-text="address.name_line"
                  item-value="address.name_line"
                  v-model="contactName"
                  append-icon=""
                  outlined
                  dense
                  :label="$t('name')"
                  :return-object="false"
                  ref="nameComboBox"
                  @focus="customerNameInputActive = true"
                  @blur="customerNameInputActive = false"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-3 pb-0"
              >
                <div
                  style="font-size: 16px; color: #6b6b6b; font-weight: 600"
                  class="d-flex align-center"
                >
                  <div>{{ $t(typeOfBooking) }} {{ $t('information').toLowerCase() }}</div>
                  <div class="flex-grow-1 ml-2">
                    <v-divider />
                  </div>
                </div>
              </v-col>
              <v-col
                v-if="locations !== null && locations.length > 1"
                cols="12"
                md="12"
                lg="12"
                class="pb-1"
              >
                <v-select
                  hide-details
                  :label="$t('location')"
                  outlined
                  dense
                  v-model="selectedLocation"
                  :items="locations"
                  item-value="uuid"
                  item-text="label"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="pb-1"
                v-if="showItemSelection"
              >
                <v-select
                  hide-details
                  outlined
                  :label="$t('item')"
                  dense
                  v-model="selectedItem"
                  :items="itemsSelection"
                  item-value="uuid"
                  item-text="title"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                class="pt-3"
              >
                <div
                  style="font-size: 16px; color: #6b6b6b; font-weight: 600"
                  class="d-flex align-center"
                >
                  <div>{{ $t("timeOfBooking") }}</div>
                  <div class="flex-grow-1 ml-2">
                    <v-divider />
                  </div>
                </div>
              </v-col>
              <v-col
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >
                <v-menu
                  :disabled="processingBooking || !hasSelectedLocation"
                  ref="selectDate"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attr }">
                    <v-text-field
                      readonly
                      :label="$t('date')"
                      outlined
                      dense
                      v-model="dateString"
                      :placeholder="$t('pleaseSelectADate')"
                      v-bind="attr"
                      v-on="on"
                      hide-details
                    />
                  </template>
                  <v-date-picker
                    v-model="datePicked"
                    no-title
                    @input="dateMenu = false"
                    style="min-height: 350px"
                  />
                </v-menu>
              </v-col>
              <v-col
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >

                <TimeSelectorV2
                  :initial-time="startDateAndTime"
                  @selectTime="selectTime"
                  :label="$t('arrival')"
                  dense
                />
              </v-col>

              <v-col
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >
                <v-select
                  hide-details
                  :label="$t('quantity')"
                  dense
                  outlined
                  v-model="quantity"
                  :items="quantityOptions"
                />
              </v-col>
              <v-col
                :cols="mainElementWidth <= 640 ? '12' : '6'"
              >
                <v-text-field
                  hide-details
                  dense
                  outlined
                  :label="$t('duration')"
                  v-model="durationInMinutes"
                  suffix="min"
                />
              </v-col>
              <v-col
                cols="12"
                class="pt-0"
                ref="tablePicker"
                v-if="
                  availableLocationVariations &&
                    availableLocationVariations.length > 0 &&
                    selectedItemObject !== undefined &&
                    !selectedItemObject.title.toLowerCase().includes('jólahlaðborð') &&
                    (selectedItemObject.title.toLowerCase().includes('borð') ||
                      selectedItemObject.title.toLowerCase().includes('table'))
                "
              >
                <div
                  class="pt-3 d-flex align-center"
                  style="font-size: 16px; font-weight: 600; color: #6b6b6b"
                >
                  <div>
                    {{ $t("pickATable") }}
                  </div>
                  <div class="flex-grow-1 ml-2">
                    <v-divider />
                  </div>
                </div>
                <v-expansion-panels
                  flat
                  mandatory
                >
                  <v-expansion-panel
                    v-for="zone in zones"
                    :key="zone.uuid"
                  >
                    <v-expansion-panel-header
                      v-if="zoneHasVariations(zone)"
                      class="px-0"
                    >
                      <div
                        class="pl-0"
                        style="color: #6b6b6b"
                      >
                        {{ zone.name }}
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <div :key="zone.uuid">
                        <template
                          v-for="variation in availableLocationVariations"
                        >
                          <v-btn
                            v-if="variation.zone.uuid === zone.uuid"
                            :key="variation.uuid"
                            small
                            depressed
                            :loading="fetchingLocationVariations"
                            class="mr-2 mb-2"
                            color="primary"
                            :outlined="
                              tables === null ||
                                tables.length <= 0 ||
                                !tables.includes(variation.uuid)
                            "
                            @click="variationClick(variation)"
                            :disabled="processingBooking"
                            style="text-transform: inherit"
                            :style="
                              tables !== null &&
                                tables.length > 0 &&
                                tables.includes(variation.uuid)
                                ? 'border: 1px solid white !important'
                                : ''
                            "
                          >
                            {{ variation.label }}
                          </v-btn>
                        </template>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
          <v-divider
            vertical
            class="mt-md-6"
          />
          <v-col
            cols="12"
            md="5"
            class="pb-0 pl-md-6"
          >
            <v-row align="center">
              <v-col
                cols="12"
              >
                <div
                  style="height: 36px;"
                  class="d-flex align-center justify-end"
                >
                  <div
                    v-if="bookingObject && bookingObject.booking_id"
                    style="font-size: 0.7rem; font-weight: 300; line-height: 0.9rem;"
                    class="text-right"
                  >
                    <div>{{ $t('bookingId') }}</div><div># {{ bookingObject.booking_id }}</div>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pt-3 pb-0"
              >
                <div
                  style="font-size: 16px; color: #6b6b6b; font-weight: 600"
                  class="d-flex align-center"
                >
                  <div>{{ $t('Extra') }}</div>
                  <div class="flex-grow-1 ml-2">
                    <v-divider />
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-1"
              >
                <v-textarea
                  :disabled="processingBooking"
                  :label="$t('externalNote')"
                  v-model="externalNote"
                  auto-grow
                  hide-details
                  outlined
                  dense
                  rows="1"
                  row-height="15"
                />
              </v-col>
              <v-col
                cols="12"
                class="pb-1"
              >
                <v-textarea
                  :disabled="processingBooking"
                  :label="$t('internalNote')"
                  v-model="internalNote"
                  auto-grow
                  hide-details
                  outlined
                  dense
                  rows="1"
                  row-height="15"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-if="
                    staff !== null && staff !== undefined && staff.length > 0
                  "
                  :disabled="processingBooking || !hasSelectedLocation"
                  hide-details
                  :label="$t('staff') + ' (' + $t('optional') + ')'"
                  v-model="selectedStaffName"
                  outlined
                  dense
                  :items="staff"
                  item-value="address.name_line"
                  item-text="address.name_line"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
                lg="12"
                class="py-0"
              >
                <v-checkbox
                  v-model="sendNotification"
                  :label="$t('sendNotificationEmail')"
                  :disabled="processingBooking"
                  color="primary"
                  hide-details
                  :ripple="false"
                  dense
                  class="ma-0"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  outlined
                  block
                  text
                  class="text-none"
                  @click="viewCustomerBookingHistory"
                >
                  {{ $t('View booking history') }}
                  <v-icon>
                    mdi-menu-right
                  </v-icon>
                </v-btn>
              </v-col>
              <!--              <v-col-->
              <!--                v-if="selectedTimeSlotExists"-->
              <!--                cols="6"-->
              <!--              >-->
              <!--                {{ $moment(selectedTimeSlot.timestampInMillis).format('DD/MM/YYYY HH:mm') }} ({{ ((selectedTimeSlot.paddingInMillis + 1) / 1000) / 60 }} min)-->
              <!--              </v-col>-->
              <!--              <v-col-->
              <!--                cols="6"-->
              <!--                align="end"-->
              <!--              >-->
              <!--                <v-btn-->
              <!--                  color="blue"-->
              <!--                  outlined-->
              <!--                  plain-->
              <!--                  :disabled="!canCreateBooking"-->
              <!--                  @click="exportPDF"-->
              <!--                >-->
              <!--                  Export pdf-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
            </v-row>
            <v-divider />
            <v-row>
              <v-col cols="12">
                <v-card flat>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center">
                      <div>
                        {{ $t('Current PAX') }}
                      </div>
                      <div class="ml-1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on="on"
                              v-bind="attrs"
                              small
                              color="blue"
                            >
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <span>
                            {{ $t('Current PAX is determined (if applicable) by selected location, item, date and time') }}
                          </span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <v-icon
                        small
                        class="mr-1"
                        color="blue"
                        :class="fetchingSelectionBookingsCount ? 'icon-spin' : ''"
                        @click="fetchingSelectionBookingsCount = true"
                        :disabled="fetchingSelectionBookingsCount"
                      >
                        mdi-sync
                      </v-icon>
                      <div class="d-flex">
                        <v-skeleton-loader
                          v-if="fetchingSelectionBookingsCount"
                          type="image"
                          width="20"
                          height="20px"
                          class="mr-1"
                        />
                        <div
                          v-else
                          class="mr-1"
                        >
                          {{ currentPAX }}
                        </div>
                        <div>{{ maxPAX ? $t('of') + ' ' + maxPAX : '' }}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      v-if="closeToOverBooked"
                      class="warning--text d-flex align-center justify-end"
                    >
                      <v-icon
                        color="warning"
                        small
                        class="mr-1"
                      >
                        mdi-alert
                      </v-icon> {{ $t('Close to over-booking') }}
                    </div>
                    <div
                      v-if="paxOverBooked"
                      class="error--text d-flex align-center justify-end font-weight-bold"
                    >
                      <v-icon
                        color="error"
                        small
                        class="mr-1"
                      >
                        mdi-alert-octagon
                      </v-icon> {{ $t('Over-booked!') }}
                    </div>
                  </div>
                  <div class="py-3">
                    <v-divider />
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ $t("date") }}
                    </div>
                    <div>
                      {{ $moment(selectedTimeSlot.timestampInMillis).format("DD/MM/YYYY") }}
                    </div>
                  </div>
                  <div
                    v-if="dateIsInThePast"
                    class="warning--text d-flex align-center justify-end"
                  >
                    <v-icon
                      small
                      color="warning"
                      class="mr-1"
                    >
                      mdi-alert
                    </v-icon>
                    <div>
                      {{ $t('Selected date is in the past') }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ $t('time') }}
                    </div>
                    <div>
                      {{ $moment(selectedTimeSlot.timestampInMillis).format("HH:mm") }}
                    </div>
                  </div>
                  <div
                    v-if="timeIsInThePast"
                    class="warning--text d-flex align-center justify-end"
                  >
                    <v-icon
                      small
                      color="warning"
                      class="mr-1"
                    >
                      mdi-alert
                    </v-icon>
                    <div>
                      {{ $t('Selected time is in the past') }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ $t('duration') }}
                    </div>
                    <div>
                      {{ (selectedTimeSlot.paddingInMillis + 1) / 1000 / 60 }} {{ $t("minuteShort") }}
                    </div>
                  </div>
                  <div
                    v-if="durationBeyondLocationOpeningHour"
                    class="d-flex justify-end align-center warning--text"
                  >
                    <v-icon
                      small
                      color="warning"
                      class="mr-1"
                    >
                      mdi-alert
                    </v-icon>
                    <div>
                      {{ $t('Duration beyond location closing time') }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div>
                      {{ $t('Quantity') }}
                    </div>
                    <div>
                      {{ quantity }}
                    </div>
                  </div>
                  <div
                    class="d-flex justify-end align-center"
                    v-if="warningText"
                  >
                    <v-icon
                      small
                      color="warning"
                      class="mr-1"
                    >
                      mdi-alert
                    </v-icon>
                    <div>
                      {{ warningText }}
                    </div>
                  </div>
                  <div class="py-3">
                    <v-divider />
                  </div>
                  <div
                    v-if="bookingObject"
                    class="d-flex justify-space-between"
                  >
                    <div>
                      {{ $t('Last updated') }}
                    </div>
                    <div>
                      {{ changedFormatted }}
                    </div>
                  </div>
                  <div
                    v-if="bookingObject"
                    class="d-flex justify-space-between"
                  >
                    <div>
                      {{ $t('Created on') }}
                    </div>
                    <div>
                      {{ createdFormatted }}
                    </div>
                  </div>
                  <div
                    v-if="bookingObject && !bookingObject.channel"
                    class="d-flex justify-end align-center warning--text"
                  >
                    <v-icon
                      small
                      color="warning"
                      class="mr-1"
                    >
                      mdi-information-outline
                    </v-icon>
                    <div>
                      {{ $t('Created in back-office') }}
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col
            v-if="warningText"
            cols="12"
            md="6"
            order-md="2"
            order="1"
          >
            <v-alert
              type="info"
              class="mb-0"
              outlined
            >
              <span>
                {{ warningText }}
              </span>
            </v-alert>
          </v-col>
        </v-row>
        <v-divider />
        <v-row
          v-if="orderLineItemProducts && orderLineItemProducts.length > 0"
          class="px-3"
          no-gutters
        >
          <v-list
            class="pt-4"
            dense
          >
            <div
              class="pl-3"
              style="font-size: 16px; color: #6b6b6b; font-weight: 600"
            >
              {{ $t("upsell") }}
            </div>
            <template v-for="(lineItem, index) in orderLineItemProducts">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title style="color: rgb(107, 107, 107)">
                    {{ lineItem.quantity }} x {{ lineItem.label }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-row>
        <div
          style="font-size: 12px; font-weight: 400"
          class="d-flex justify-end pa-3"
        >
          <div>
            <v-menu
              v-if="bookingObjectExists"
              offset-y
            >
              <template v-slot:activator="{ on, attrs, value }">
                <v-btn
                  v-if="bookingIsActive"
                  large
                  class="mr-2"
                  text
                  style="text-transform: inherit"
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("more") }}
                  <v-icon
                    v-if="value"
                    right
                  >
                    mdi-chevron-up
                  </v-icon>
                  <v-icon
                    v-else
                    right
                  >
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="bookingAction in bookingActions">
                  <v-list-item
                    v-if="bookingAction"
                    :key="bookingAction.key"
                    class="px-3"
                  >
                    <v-btn
                      text
                      depressed
                      block
                      style="text-transform: inherit"
                      :color="bookingAction.color"
                      @click="bookingAction.action"
                    >
                      {{ bookingAction.title }}
                    </v-btn>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              v-if="bookingIsActive"
              outlined
              large
              class="mr-2"
              @click="processBookingRedemption"
              style="text-transform: inherit"
            >
              {{ checkInText }}
            </v-btn>
            <v-btn
              v-if="bookingObject && bookingObject.uuid && bookingIsActive"
              color="primary"
              depressed
              large
              :disabled="!canCreateBooking"
              @click="updateBooking"
              :loading="processingBooking"
              style="text-transform: inherit"
            >
              {{ $t("update") }}
            </v-btn>
            <v-btn
              v-else-if="!bookingObjectExists"
              color="primary"
              depressed
              large
              :disabled="!canCreateBooking"
              @click="processBooking"
              :loading="processingBooking"
              style="text-transform: inherit"
            >
              {{ $t("save") }}
            </v-btn>
            <v-menu
              v-if="bookingIsActive"
              top
              offset-y
              nudge-top="4px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="bookingObjectExists"
                  :color="
                    !currentBookingObjectState
                      ? 'primary'
                      : currentBookingObjectState.color
                  "
                  :dark="
                    !currentBookingObjectState &&
                      !updatingBookingState &&
                      !processingBooking
                      ? true
                      : !colorIsLight(currentBookingObjectState.color)
                  "
                  large
                  depressed
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  style="
										text-transform: inherit;
										font-size: 16px;
										font-weight: 700;
									"
                  :loading="updatingBookingState"
                  :disabled="
                    processingBooking || !bookingObject || !bookingObject.uuid
                  "
                >
                  <div>
                    <span v-if="!currentBookingObjectState">
                      {{ $t("status") }}
                    </span>
                    <span v-else>
                      {{ currentBookingObjectState.text }}
                    </span>
                    <v-icon
                      v-if="on"
                      right
                    >
                      mdi-chevron-down
                    </v-icon>
                    <v-icon v-else>
                      mdi-chevron-up
                    </v-icon>
                  </div>
                </v-btn>
              </template>
              <v-list
                dense
                class="py-0"
              >
                <template v-for="state in bookingStates">
                  <v-list-item
                    :key="state.value"
                    v-if="
                      state.value !== 'canceled' && state.value !== 'cancelled'
                    "
                    @click="updateBookingState(state)"
                  >
                    <v-list-item-avatar
                      :color="state.color"
                      size="8"
                    />
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ state.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              v-if="bookingIsCancelled"
              large
              color="red"
              depressed
              :loading="processingBooking"
              :disabled="processingBooking"
              @click="toggleBookingStatus"
            >
              {{ $t("reactivateBooking") }}
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
    <v-dialog
      v-if="showConfirmCancel"
      id="cancel-confirm"
      persistent
      :value="showConfirmCancel"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t("confirmCancelBookingText") }}
        </v-card-title>
        <v-card-text>
          {{
            $t("confirmCancelBookingInfo", {
              customer:
                bookingObject &&
                bookingObject.customer &&
                bookingObject.customer.address &&
                bookingObject.customer.address.name_line
                  ? bookingObject.customer.address.name_line
                  : "",
              date: $moment(selectedTimeSlot.timestampInMillis).format(
                "DD/MM/YYYY HH:mm"
              ),
            })
          }}
        </v-card-text>
        <v-card-text v-if="cancelError">
          <v-alert type="error">
            {{ cancelError }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :disabled="processingBooking"
            @click="showConfirmCancel = false"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="processingBooking"
            :disabled="processingBooking"
            @click="toggleBookingStatus"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TimeSelectorV2 from "../common/TimeSelectorV2"
import moment from "moment"

Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h)
    return this
}

/**
 * @property availableCount
 * @property defaultQuantity
 * @property minimumOrderQuantity
 */

const SECONDS = 60
const MILLISECONDS = 1000

export default {
	name: "AddBookingCard",
	components: {
		TimeSelectorV2,
	},
	props: {
		bookingObject: {
			type: Object,
			default: () => null,
		},
		walkIn: {
			type: Boolean,
			default: false,
		},
		bookingTime: {
			type: Number,
			default: 0,
		},
		bookingItem: {
			type: String,
			default: "",
		},
		bookingDuration: {
			type: Number,
			default: 0,
		},
		calendarLocationVariations: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		const date = new Date(Date.now())
		const hours = date.getHours()
		let minutes = date.getMinutes()
		minutes =
			minutes < "15"
				? "00"
				: minutes < "30"
				? "15"
				: minutes < "45"
				? "30"
				: "45"
		const timeNow = hours.toString().padStart(2, "0") + ":" + minutes

		date.setUTCHours(hours)
		date.setUTCMinutes(Number(minutes))
		date.setSeconds(0)
		date.setMilliseconds(0)

		const durationInMinutes = 30
		const paddingInMillis = durationInMinutes * SECONDS * MILLISECONDS - 1

		const selectedTimeSlot = {
			timestampInMillis: date.getTime(),
			paddingInMillis: paddingInMillis,
		}

		let contactName
		let contactPhone
		let contactEmail
		if (
      typeof this.bookingObject !== 'undefined' &&
      this.bookingObject !== null &&
      typeof this.bookingObject.customer !== 'undefined' &&
      this.bookingObject.customer !== null
    ) {
			contactPhone = this.bookingObject.customer.phone
			contactEmail = this.bookingObject.customer.email
      if(typeof this.bookingObject.customer.address !== 'undefined' && this.bookingObject.customer.address !== null) {
        contactName = this.bookingObject.customer.address.name_line
      }
		} else if (this.walkIn) {
			contactName = this.$t("walkInTable")
		}

		return {
			menu2: false,
			e1: 1,
			selectedItem: null,
			selectedDate: new Date(),
			datePicked: null,
			fetchingAvailableSlots: null,
			availableTimeSlots: null,
			selectedTimeSlot: selectedTimeSlot,
			quantity: 2,
			internalNote: null,
			contactName: contactName,
			contactEmail: contactEmail,
			contactPhone: contactPhone,
			sendNotification: true,
			processingBooking: false,
			externalNote: null,
			tables: [],
			durationInMinutes: durationInMinutes,
			startDateAndTime: timeNow,
			selectedStaffName: "",
			availableLocationVariations: null,
			fetchingLocationVariations: false,
			dateMenu: false,
			fetchingPhone: false,
			fetchingEmail: false,
			warningText: null,
			customerBookings: null,
			gettingBookingHistory: false,
			typeOfBooking: "",
			showItemSelection: false,
			orderLineItems: [],
			updatingBookingState: false,
			bookingState: null,
			showConfirmCancel: false,
			cancelError: null,
			filteredCustomers: [],
			mainElementWidth: 0,
			customerNameInputActive: false,
			customerEmailInputActive: false,
			customerEmailAllowFetch: false,
			customerPhoneInputActive: false,
			customerPhoneAllowFetch: false,
			currentPAX: 0,
			fetchingSelectionBookingsCount: false,
			fetchingSelectionBookingsCountError: false
		}
	},
	computed: {
		dateIsInThePast() {
			if(typeof this.selectedDate === 'undefined' || this.selectedDate === null) {
				return false
			}
			const currentDateTimeStamp = new Date().toISOString().substring(0, 10)
			return currentDateTimeStamp > this.selectedDate.toISOString().substring(0, 10)
		},
		timeIsInThePast() {
			if(this.dateIsInThePast) {
				return false
			}
			if(typeof this.selectedDate === 'undefined' || this.selectedDate === null) {
				return false
			}
			const currentDateTimeStamp = new Date().toISOString().substring(11, 19)
			return currentDateTimeStamp > this.selectedDate.toISOString().substring(11, 19)
		},
		durationBeyondLocationOpeningHour() {
			return false
		},
		closeToOverBooked() {
			if(this.maxPAX !== null && this.currentPAX !== null && this.fetchingSelectionBookingsCount === false) {
				return this.currentPAX <= this.maxPAX && this.currentPAX >= this.maxPAX - 3 && this.currentPAX > 0
			}
			return false
		},
		paxOverBooked() {
			if(this.maxPAX !== null && this.fetchingSelectionBookingsCount === false) {
				return this.currentPAX > this.maxPAX
			}
			return false
		},
		maxPAX() {
			if(typeof this.selectedItemObject === 'undefined' || this.selectedItemObject === null) {
				return null
			}
			if(typeof this.selectedItemObject.maximumGroupedQuantity === 'undefined' || this.selectedItemObject.maximumGroupedQuantity === null || isNaN(Number(this.selectedItemObject.maximumGroupedQuantity))) {
				return null
			}
			return Number(this.selectedItemObject.maximumGroupedQuantity)
		},
		createdTimestamp() {
			if(typeof this.bookingObject === 'undefined' || this.bookingObject === null) {
				return null
			}
			if(typeof this.bookingObject.created === 'undefined' || this.bookingObject.created === null) {
				return null
			}
			return this.$store.getters.validateAndGetEpochTimestampInMillis(this.bookingObject.created)
		},
		changedTimestamp() {
			if(typeof this.bookingObject === 'undefined' || this.bookingObject === null) {
				return null
			}
			if(typeof this.bookingObject.changed === 'undefined' || this.bookingObject.changed === null) {
				return null
			}
			return this.$store.getters.validateAndGetEpochTimestampInMillis(this.bookingObject.changed)
		},
		createdFormatted() {
			if(typeof this.bookingObject === 'undefined' || this.bookingObject === null) {
				return null
			}
			if(typeof this.bookingObject.created === 'undefined' || this.bookingObject.created === null) {
				return null
			}

			return this.$moment(this.createdTimestamp).format('YYYY-MM-DD HH:mm:ss')
		},
		changedFormatted() {
			if(typeof this.bookingObject === 'undefined' || this.bookingObject === null) {
				return null
			}
			if(typeof this.bookingObject.changed === 'undefined' || this.bookingObject.changed === null) {
				return null
			}
			return this.$moment(this.changedTimestamp).format('YYYY-MM-DD HH:mm:ss')
		},
		checkInText() {
			if (this.bookingObject && this.bookingObject.redeemedAt) {
				return this.$t("undoCheckIn")
			}
			return this.$t("checkIn")
		},
		bookingStates() {
			return this.$store.state.availableBookingStates
		},
		currentBookingObjectState() {
			if (
				typeof this.bookingObject !== "undefined" &&
				this.bookingObject !== null
			) {
				if (this.bookingIsCancelled) {
					return this.bookingStates.find(
						(state) => state && state.value === "canceled"
					)
				} else if (
					typeof this.bookingObject.state !== "undefined" &&
					this.bookingObject.state !== null
				) {
					return this.bookingStates.find(
						(state) => state && state.value === this.bookingObject.state
					)
				}
			}
			return null
		},
		selectedDateFromStore() {
			return this.$store.state.selectedDate
		},
		reservationTableItem() {
			const tableItem = this.itemsFilteredByLocation.find(
				(item) =>
					item &&
					item.type === "event" &&
					item.status === true &&
					(!item.title.toLowerCase().includes('jólahlaðborð')) &&
					(item.title.toLowerCase().includes("borð") ||
						item.title.toLowerCase().includes("table"))
			)
			if (typeof tableItem !== "undefined") {
				return tableItem
			}
			return null
		},
		bookingActions() {
			return [
				{
					key: "cancel",
					title: this.$t("cancelBooking"),
					color: "red",
					action: this.toggleBookingStatus,
				},
				{
					key: "save_as_new",
					title: this.$t("saveAsNewBooking"),
					color: "black",
					action: this.processBooking,
				},
			]
		},
		showDurationBeyondLocationClosingHourWarning() {
			return false
			/*
			if(typeof this.selectedLocationObject !== 'undefined' && this.selectedLocationObject !== null) {
				if(Array.isArray(this.selectedLocationObject.opening_hours)) {
					let dateToUse = new Date()
					if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
						dateToUse = this.selectedDate
					}

					const locationOpeningHour = this.selectedLocationObject.opening_hours.filter(h => h.day.toString() === dateToUse.getUTCDay().toString())

					if(Array.isArray(locationOpeningHour)) {
						const lastOpeningHour = locationOpeningHour[locationOpeningHour.length - 1]

						if(lastOpeningHour === undefined || lastOpeningHour === null) {
							return false
						}

						let closedHour = lastOpeningHour.endhours
						if(typeof closedHour === 'undefined' || closedHour === null) {
							closedHour = '0000'
						}
						// Assume hour is on the form of 900, we then want it to be 0900
						if(closedHour.length === 3) {
							closedHour = closedHour.padStart(4, '0')
						}

						// Assume hour is on the form of 17, as in the first two numbers of an hour
						if(closedHour.length === 2) {
							closedHour = closedHour.padEnd(4, '0')
						}

						// Assume hour is on the form of 9, as in the first single number of an hour
						if(closedHour.length === 1) {
							closedHour = closedHour.padStart(2, '0').padEnd(4, '0')
						}

						const hour = Number(closedHour.substr(0, 2))
						const minute = Number(closedHour.substr(2, 2))
						const closedHourDateInMillis = new Date(dateToUse).setUTCHours(hour, minute, 0, 0)

						let durationInMillis = this.durationInMinutes * SECONDS * MILLISECONDS
						const dateToUseInMillis = dateToUse.getTime() + durationInMillis

						return dateToUseInMillis > closedHourDateInMillis
					}
				}
			}
			*/
		},
		selectedLocation: {
			get() {
				return this.$store.state.selectedLocation
			},
			set(value) {
				this.$store.commit("updateSelectedLocation", value)
			},
		},
		selectedLocationObject() {
			return this.locations.find(
				(location) => location.uuid === this.selectedLocation
			)
		},
		staff() {
			return this.$store.state.staff
		},
		hasSelectedLocation() {
			return (
				this.selectedLocationObject !== null &&
				this.selectedLocationObject !== undefined
			)
		},
		attributes() {
			return [
				{
					key: "today",
					highlight: true,
					dates: this.selectedDate,
				},
			]
		},
		selectedTimeSlotExists() {
			return (
				typeof this.selectedTimeSlot !== "undefined" &&
				typeof this.selectedTimeSlot.timestampInMillis !== "undefined" &&
				typeof this.selectedTimeSlot.paddingInMillis !== "undefined"
			)
		},
		minQuantity() {
			if (
				this.selectedItemObject !== undefined &&
				this.selectedItemObject.minimumOrderQuantity !== null &&
				this.selectedItemObject.minimumOrderQuantity !== undefined
			) {
				return this.selectedItemObject.minimumOrderQuantity
			}

			return 1
		},
		maxQuantity() {
			return 999
		},
		selectedItemObject() {
			if (Array.isArray(this.$store.state.items)) {
				return this.$store.state.items.find(
					(item) => item.uuid === this.selectedItem
				)
			}
			return null
		},
		items() {
			if (!Array.isArray(this.$store.state.items)) {
				return []
			}
			return this.$store.state.items
				.filter(
					(item) =>
            typeof item !== 'undefined' &&
            item !== null &&
						item.type === "event" &&
						item.status === true &&
						(item.startDate === 0 ||
							item.endDate === 0 ||
							item.endDate > this.startTimeInMillis)
				)
				.sort((p1, p2) => {
					let w1 = 0
					let w2 = 0

					if (p1.title.toLowerCase().includes("borð") && !p1.title.toLowerCase().includes('jólahlaðborð')) {
						w1 = -99
					}

					if (p2.title.toLowerCase().includes("borð")&& !p1.title.toLowerCase().includes('jólahlaðborð')) {
						w2 = -99
					}

					if (p1.title.toLowerCase().includes("table")) {
						w1 = -99
					}

					if (p2.title.toLowerCase().includes("table")) {
						w2 = -99
					}

					return w1 - w2
				})
		},
		itemsFilteredByLocation() {
			return this.items.filter((item) => {
				if(typeof item === 'undefined' || item === null) {
					return false
				}
				if(typeof item.location === 'undefined' || item.location === null) {
					return true
				}
				if(typeof this.selectedLocation === 'undefined' || this.selectedLocation === null) {
					return true
				}
				return item.location.uuid === this.selectedLocation
			})
		},
		itemsSelection() {
			if (this.typeOfBooking === "event" && this.reservationTableItem !== null) {
				return this.itemsFilteredByLocation.filter((item) => item && ((!item.title.toLowerCase().includes("borð") && !item.title.toLowerCase().includes("table")) || item.title.toLowerCase().includes('jólahlaðborð')) )
			}
			return this.itemsFilteredByLocation
		},
		canContinueToContact() {
			/*
			 *
			 *
			 * */
			return (
				this.selectedTimeSlotExists === true && this.selectedLocation !== null
			)
		},
		canContinueToDateAndTime() {
			if (this.selectedItem !== null) {
				return true
			}

			return false
		},
		canCreateBooking() {
			if (!this.hasSelectedLocation) {
				return false
			}

			if (
				typeof this.selectedItem === "undefined" ||
				this.selectedItem === null
			) {
				return false
			}

			if (
				this.contactName !== null &&
				this.selectedLocation !== null &&
				this.selectedTimeSlotExists === true
			) {
				return true
			}

			return false
		},
		channelUUID() {
			return ""
		},
		customers() {
			if (this.$store.state.customers !== null) {
				return this.$store.state.customers.filter(
					(customer) =>
            typeof customer !== 'undefined' &&
						customer !== null &&
            typeof customer.address !== 'undefined' &&
            customer.address !== null &&
            typeof customer.address.name_line !== 'undefined' &&
						customer.address.name_line !== null
				)
			}
			return []
		},
		locations() {
			return this.$store.state.locations.filter(
				(location) =>
          typeof location !== 'undefined' &&
          location !== null &&
          typeof location.label !== 'undefined' &&
					location.label !== null &&
					location.label !== "" &&
					location.active === true
			)
		},
		locationVariations() {
			let location = this.locations.find(
				(loc) => loc.uuid === this.selectedLocation
			)
			if (location) {
				return location.variations
			}
			return null
		},
		quantityOptions() {
			const options = []

			for (let i = this.minQuantity; i <= this.maxQuantity; i++) {
				options.push(i)
			}

			return options
		},
		zones() {
			const sortingWords = [
				"borð",
				"salur",
				"spa",
				"úti",
				"inni",
				"uppi",
				"niðri",
				"gluggar",
				"vip",
			]
			const zones = this.$store.state.zones
			const sortedZones = zones.sort(
				(a, b) =>
					sortingWords.includes(b.name.toLowerCase()) -
					sortingWords.includes(a.name.toLowerCase())
			)
			return sortedZones
		},
		organizationUUID() {
			return this.$store.state.organizationUUID
		},
		dateString() {
			let date = new Date(this.selectedTimeSlot.timestampInMillis)
			return this.$moment(date).format("DD/MM/YYYY")
		},
		startTimeInMillis() {
			return this.$store.state.startTimeInMillis
		},
		bookingObjectExists() {
			return (
				typeof this.bookingObject !== "undefined" &&
				this.bookingObject !== null &&
				typeof this.bookingObject.uuid !== "undefined" &&
				this.bookingObject.uuid !== null
			)
		},
		bookingExists() {
			return (
				typeof this.booking !== "undefined" &&
				this.booking !== null &&
				typeof this.booking.uuid !== "undefined" &&
				this.booking.uuid !== null
			)
		},
		bookingIsActive() {
			if (this.bookingObjectExists && this.bookingObject.status === "1") {
				return true
			}
			return false
		},
		bookingIsCancelled() {
			if (this.bookingObjectExists && this.bookingObject.status === "0") {
				return true
			}
			return false
		},
		orderLineItemProducts() {

			if(this.orderLineItems === null || this.orderLineItems === undefined) {
				return []
			}

      if(!Array.isArray(this.orderLineItems)) {
        return []
      }
			return this.orderLineItems.filter(
				(lineItem) =>
					typeof lineItem !== "undefined" &&
					lineItem !== null &&
					lineItem.namespace === "product"
			)
		},
	},
	watch: {
		fetchingSelectionBookingsCount(value) {
			if(value === true) {
				this.getSelectionBookingCount()
			}
		},
		contactPhone(value) {
			if (this.customerPhoneAllowFetch && value !== null && value !== undefined && value.length === 7) {
				this.fetchCustomerByPhoneNumber(value)
				this.customerPhoneAllowFetch = false
			}
		},
		contactEmail(value) {
			if (this.customerEmailAllowFetch && value !== null && value !== undefined) {
				this.fetchCustomerByEmail(value)
				this.customerEmailAllowFetch = false
			}
		},
		selectedItemObject(selectedItemObject) {
			if (typeof selectedItemObject !== 'undefined' && selectedItemObject !== null) {
				const defaultValue = 30
				this.durationInMinutes = defaultValue
				if (selectedItemObject.durationInMinutes > defaultValue) {
					this.durationInMinutes = selectedItemObject.durationInMinutes
				}

				if (typeof this.bookingDuration !== "undefined") {
					if (this.durationInMinutes < this.bookingDuration) {
						this.durationInMinutes = this.bookingDuration
					}
				}

				// Setting selected item quantity when selected item object is changed
				if (!this.bookingObjectExists) {
					if (typeof selectedItemObject.defaultQuantity !== "undefined") {
						if (selectedItemObject.defaultQuantity > 0) {
							this.quantity = selectedItemObject.defaultQuantity
						} else {
							if (
								Array.isArray(this.quantityOptions) &&
								this.quantityOptions.length > 0
							) {
								this.quantity = this.quantityOptions[0]
							} else {
								this.quantity = 1
							}
						}
					}
				}

				this.listAvailableLocationVariations()

			}
		},
		durationInMinutes(newValue, oldValue) {
			if (this.selectedTimeSlotExists === true) {
				this.selectedTimeSlot.paddingInMillis =
					newValue * SECONDS * MILLISECONDS - 1
				this.listAvailableLocationVariations()
			}

			if(typeof oldValue !== 'undefined' && oldValue !== null && typeof newValue !== 'undefined' && newValue !== null && newValue !== oldValue) {
				setTimeout(() => {
					if(newValue === this.durationInMinutes) {
						this.fetchingSelectionBookingsCount = true
					}
				}, 500)
			}
		},
		startDateAndTime(newValue) {
			if (newValue === null || (newValue && newValue.length < 5)) {
				return
			}

			let dateMomentObject = this.$moment(this.dateString, "DD/MM/YYYY")
			let date = dateMomentObject.toDate()

			const timeArr = newValue.split(":")

			const hours = timeArr[0]
			const minutes = timeArr[1]

			date.setUTCHours(hours)
			date.setUTCMinutes(minutes)

			const paddingInMillis =
				this.durationInMinutes * SECONDS * MILLISECONDS - 1

			this.selectedTimeSlot = {
				timestampInMillis: date.getTime(),
				paddingInMillis: paddingInMillis,
			}

			this.listAvailableLocationVariations()
		},
		e1() {
			this.getAvailableTimeSlots()
		},
		quantity(quantity) {
			this.getAvailableTimeSlots()

			if (
				typeof this.startDateAndTime === "undefined" ||
				this.startDateAndTime === "" ||
				this.durationInMinutes === 0
			) {
				this.selectedTimeSlot = null
			}

			// Displaying a warning towards the user
			this.warningText = null
			if (
				Array.isArray(this.quantityOptions) &&
				this.quantityOptions.length > 0
			) {
				// If selected quantity is less than 'minimum selectable quantity'
				if (quantity < this.quantityOptions[0]) {
					this.warningText = this.$t("selectedQuantityLessThanMinimumSelectable")
				}
				// If selected quantity is greater than 'maximum selectable quantity'
				if (quantity > this.quantityOptions[this.quantityOptions.length - 1]) {
					this.warningText = this.$t("selectedQuantityGreaterThanMaximumSelectable")
				}
			}

			// If selected quantity is zero or less for some reason
			if (quantity <= 0) {
				this.warningText = this.$t("zeroOrLessQuantity")
			}
		},
		selectedDate(newValue) {
			const hours = Number(this.startDateAndTime.split(":")[0])
			const minutes = Number(this.startDateAndTime.split(":")[1])
			this.selectedTimeSlot = {
				timestampInMillis: newValue.setUTCHours(hours, minutes, 0, 0),
				paddingInMillis: this.durationInMinutes * SECONDS * MILLISECONDS - 1
			}
			this.getAvailableTimeSlots()
		},
		datePicked(currentValue) {
			this.selectedDate = new Date(currentValue)
		},
		tables() {
			this.getAvailableTimeSlots()

			if (
				typeof this.startDateAndTime !== "undefined" &&
				this.startDateAndTime !== "" &&
				this.startsAtTime !== undefined
			) {
				this.selectedTimeSlot = null
			}
		},
		selectedLocation(currentValue, previousValue) {
			if (Array.isArray(this.itemsSelection) && this.itemsSelection.length > 0) {
				if(!this.itemsSelection.some(item => item && item.uuid === this.selectedItem)) {
					this.selectedItem = this.itemsSelection[0].uuid
				}
			}
			this.listAvailableLocationVariations()

			if(typeof currentValue !== 'undefined' && currentValue !== null && typeof previousValue !== 'undefined' && previousValue !== null && currentValue !== previousValue) {
				this.fetchingSelectionBookingsCount = true
			}
		},
		selectedItem(currentValue, previousValue) {
			this.listAvailableLocationVariations()
			if(typeof currentValue !== 'undefined' && currentValue !== null && typeof previousValue !== 'undefined' && previousValue !== null && currentValue !== previousValue) {
				this.fetchingSelectionBookingsCount = true
			}
		},
		typeOfBooking(newValue) {
			this.selectedItem = null
			if (newValue === "event") {
				if(typeof this.bookingObject !== 'undefined' && this.bookingObject !== null) {
					if(typeof this.bookingObject.item !== 'undefined' && this.bookingObject.item !== null) {
						this.selectedItem = this.bookingObject.item.uuid
					}
				} else if(typeof this.bookingItem !== 'undefined' && this.bookingItem !== null) {
					this.selectedItem = this.bookingItem
				} else if(Array.isArray(this.items) && this.items.length > 0) {
					this.selectedItem = this.items[0].uuid
				} else {
					this.selectedItem = null
				}
				this.showItemSelection = true
			}
			if (newValue === "reservation") {
				if (this.reservationTableItem !== null) {
					this.selectedItem = this.reservationTableItem.uuid
					this.showItemSelection = false
				} else {
					this.showItemSelection = true
				}
			}
		},
		selectedTimeSlot(currentValue, previousValue) {
			if(typeof currentValue === 'undefined' || currentValue === null) {
				return
			}
			if(typeof previousValue === 'undefined' || previousValue === null) {
				return
			}
			if(currentValue.timestampInMillis === previousValue.timestampInMillis) {
				return
			}
			this.fetchingSelectionBookingsCount = true
		}
		// bookingObject(newValue) {
		//   if(typeof newValue !== 'undefined' && newValue !== null) {
		//     if(typeof newValue.location !== 'undefined' && newValue.location !== null) {
		//       this.selectedLocation = newValue.location
		//     }
		//   }
		// }
	},
	methods: {
		getSelectionBookingCount() {
			if(typeof this.selectedItem === 'undefined' || this.selectedItem === null) {
				this.fetchingSelectionBookingsCount = false
				return
			}
			if(typeof this.selectedLocation === 'undefined' || this.selectedLocation === null) {
				this.fetchingSelectionBookingsCount = false
				return
			}
			if(typeof this.selectedTimeSlot === 'undefined' || this.selectedTimeSlot === null) {
				this.fetchingSelectionBookingsCount = false
				return
			}
			if(typeof this.selectedTimeSlot.timestampInMillis === 'undefined' || this.selectedTimeSlot.timestampInMillis === null) {
				this.fetchingSelectionBookingsCount = false
				return
			}
			if(typeof this.durationInMinutes === 'undefined' || this.durationInMinutes === null) {
				this.fetchingSelectionBookingsCount = false
				return
			}

			this.fetchingSelectionBookingsCountError = false

			const startTimeInMillis = this.selectedTimeSlot.timestampInMillis
			const endTimeInMillis = this.selectedTimeSlot.timestampInMillis + this.durationInMinutes * 60 * 1000
			this.$store.dispatch('getBookingsCountInTimePeriod', { itemUUID: this.selectedItem, locationUUID: this.selectedLocation, startTimeInMillis: startTimeInMillis, endTimeInMillis: endTimeInMillis }).then(result => {
				if(typeof result !== 'undefined' && result !== null) {
					this.currentPAX = result
				}
			}).catch(() => {
				this.fetchingSelectionBookingsCountError = true
			}).finally(() => {
				this.fetchingSelectionBookingsCount = false
			})
		},
		viewCustomerBookingHistory() {
			this.$store.commit('updateContextOverlay', null)
			this.$store.commit('updateContextOverlay', {
				title: 'Customer Booking History',
				component: 'components/booking/BookingListSimple',
				props: {
					bookings: this.customerBookings,
					loadItemsCallback: this.getBookingHistory,
					showSearch: false,
				}
			})
		},
		updateCustomerPhoneInputFocus() {
			this.customerPhoneInputActive = true
			this.customerEmailAllowFetch = false
			this.customerPhoneAllowFetch = true
		},
		updateCustomerEmailInputFocus() {
			this.customerEmailInputActive = true
			this.customerPhoneAllowFetch = false
			this.customerEmailAllowFetch = true
		},
		onResize() {
			try {
				this.mainElementWidth = this.$refs["add-booking-card"].clientWidth
			} catch (e) {
				//
			}
		},
		colorIsLight(color) {
			return this.$store.getters.colorIsLight(color)
		},
		updateBookingState(state) {
			if (
				typeof this.bookingObject !== "undefined" &&
				this.bookingObject !== null
			) {
				this.updatingBookingState = true
				this.processingBooking = true

				if (
					typeof this.bookingObject.uuid !== "undefined" &&
					this.bookingObject.uuid !== null
				) {
					this.$store
						.dispatch("updateBookingState", {
							bookingUUID: this.bookingObject.uuid,
							newBookingState: state.value,
						})
						.then((result) => {
							// Return result should be a boolean so if it is false it had an error of some kind
							if (result) {
								// updated
								this.getBookingHistory()
							}
						})
						.catch(() => {
							// Handling error
						})
						.finally(() => {
							this.updatingBookingState = false
							this.processingBooking = false
						})
				} else {
					this.bookingState = state
					this.updatingBookingState = false
					this.processingBooking = false
				}
			}
		},
		fetchCustomerByPhoneNumber(phone) {
			this.fetchingPhone = true
			this.$store
				.dispatch("fetchCustomerByPhone", phone)
				.then((customer) => {
					if (customer !== null && customer !== undefined) {
						this.contactEmail = customer.email
						if (
							customer.address !== null &&
							customer.address !== undefined &&
							customer.address.name_line !== null &&
							customer.address.name_line
						) {
							this.contactName = customer.address.name_line

							if(this.bookingObject !== null && this.bookingObject.customer === null) {
								this.bookingObject.customer = customer
							}

							this.getCustomerBookings(customer.uuid)
						}
					}
				})
				.finally(() => {
					this.fetchingPhone = false
				})
		},
		fetchCustomerByEmail(email) {
			this.fetchingEmail = true
			this.$store
				.dispatch("fetchCustomerByEmail", email)
				.then((customer) => {
					if (customer !== null && typeof customer !== 'undefined') {
						this.contactPhone = customer.phone
						if (
              typeof customer.address !== 'undefined' &&
							customer.address !== null &&
              typeof customer.address.name_line !== 'undefined' &&
							customer.address.name_line !== null
						) {
							this.contactName = customer.address.name_line

							this.getCustomerBookings(customer.uuid)
						}
					}
				})
				.finally(() => {
					this.fetchingEmail = false
				})
		},
		canSelectSlot(slot) {
			if (
				this.$store.state.organization.uuid ===
				"32036a02-fd37-4044-bbb1-e55970e4531f"
			) {
				return true
			}

			return slot.availableCount >= this.quantity
		},
		onDayClick(day) {
			this.selectedDate = day.date
		},
		closed() {
			this.$emit("close")
		},
		getAvailableTimeSlots() {
			this.fetchingAvailableSlots = true
			this.$store
				.dispatch("getAvailableTimeSlotsForBookings", {
					itemUUID: this.selectedItem,
					locationUUID: this.selectedLocation,
					selectedDate: this.selectedDate,
					channelUUID: this.channelUUID,
				})
				.then((results) => {
					this.availableTimeSlots = results
				})
				.finally(() => {
					this.fetchingAvailableSlots = false
				})
		},
		listAvailableLocationVariations() {
			if (
				!this.selectedLocation ||
				!this.startDateAndTime ||
				!this.durationInMinutes
			) {
				return
			}

			if (
				this.selectedItemObject === undefined ||
				this.selectedItemObject.title.toLowerCase().includes('jólahlaðborð') ||
				(!this.selectedItemObject.title.toLowerCase().includes("borð") &&
					!this.selectedItemObject.title.toLowerCase().includes("table"))
			) {
				return
			}

			const payload = {
				location: this.selectedLocation,
				startTimeInMillis: Math.round(
					this.selectedTimeSlot.timestampInMillis / 1000
				),
				endTimeInMillis: Math.round(
					(this.selectedTimeSlot.timestampInMillis +
						this.selectedTimeSlot.paddingInMillis +
						1) /
						1000
				),
			}

			this.fetchingLocationVariations = true
			this.$store
				.dispatch("listAvailableLocationVariations", payload)
				.then((response) => {
					this.availableLocationVariations = response.sort()
					const location = this.$store.state.locations.find(
						(l) => l.uuid === this.selectedLocation
					)
					const currentTables = location.variations.filter((v) =>
            v && this.bookingObject && Array.isArray(this.bookingObject.locationVariations) &&
						this.bookingObject.locationVariations.some(
							(locationVariation) => locationVariation && locationVariation.uuid === v.uuid
						)
					)
					this.availableLocationVariations.push(...currentTables)
					this.availableLocationVariations.sort((a, b) =>
						a.label > b.label ? 1 : -1
					)
				})
				.finally(() => {
					this.fetchingLocationVariations = false
				})
		},
		processBooking() {
			// We do not want to process a booking with no or less than zero quantity
			if (this.quantity <= 0) {
				return
			}

			this.processingBooking = true

			this.saveComboBoxes()
			this.$nextTick(() => {
				let internalNote = ""

				if (this.internalNote !== null && this.internalNote !== undefined) {
					internalNote = internalNote + this.internalNote + "\n"
				}

				if (
					this.selectedStaffName !== null &&
					this.selectedStaffName !== undefined &&
					this.selectedStaffName !== ""
				) {
					internalNote = internalNote + " by " + this.selectedStaffName
				}

				if (typeof this.contactName === "object") {
					this.contactName = this.contactName.address.name_line
				}

				this.$store
					.dispatch("createBooking", {
						item: this.selectedItem,
						description: this.$t("bookedFromEpos"),
						namespace: "event",
						contactName: this.contactName
							? this.contactName
							: this.$t("walkIn"),
						contactPhone: this.contactPhone,
						contactEmail: this.contactEmail,
						startsAtTime: Math.round(
							this.selectedTimeSlot.timestampInMillis / 1000
						),
						endsAtTime: Math.round(
							(this.selectedTimeSlot.timestampInMillis +
								this.selectedTimeSlot.paddingInMillis +
								1) /
								1000
						),
						location: this.selectedLocation,
						locationVariations:
							this.tables !== null && this.tables.length > 0
								? this.tables.map((t) => {
										return t + ""
								  })
								: null,
						sendTicket: this.sendNotification,
						notifyCustomer: this.sendNotification,
						internalNote: internalNote,
						externalNote: this.externalNote,
						quantity: this.quantity,
					})
					.then((result) => {
						if (result) {
							const uuid = result.uuid
							const item = result.item
							const bookingId = result.booking_id
							const customer = result.customer
							const quantity = result.quantity
							const itemTitle = item ? item.title : ''
							const customerName = (customer && customer.address && customer.address.name_line) ? customer.address.name_line : ''
							const startsAtTime = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(result.startsAtTime)).toISOString()
							const endsAtTime = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(result.endsAtTime)).toISOString()
							const locationLabel = this.selectedLocationObject ? this.selectedLocationObject.label : ''
							this.$store.commit("updateActionSuccess", {
								message: this.$t("youHaveSuccessfullyCreatedTheBooking"),
								subMessage: this.$t('bookingInfoText', {
									bookingId: bookingId,
									bookingUUID: uuid,
									customerName: customerName,
									quantity: quantity,
									itemTitle: itemTitle,
									locationLabel: locationLabel,
									dateFrom: startsAtTime.substring(0, 10),
									timeFrom: startsAtTime.substring(11, 16),
									dateTo: endsAtTime.substring(0, 10),
									timeTo: endsAtTime.substring(11, 16)
								}),
							})
							this.$emit("close")
						} else {
							this.$store.commit("updateActionError", {
								message: this.$t("errorOccurred"),
								subMessage: this.$t("unableToProcessBooking"),
							})
						}
					})
					.catch(() => {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToProcessBooking"),
						})
					})
					.finally(() => {
						if (this.selectedDate) {
							const dateMoment = moment(this.selectedDate)

							const payload = {
								startTimeInMillis: dateMoment.startOf("day").valueOf(),
								endTimeInMillis: dateMoment.endOf("day").valueOf(),
							}

							this.$store.dispatch("getBookingsByDate", payload).finally(() => {
								this.processing = false
							})
						} else {
							this.$store
								.dispatch("getBookingsByDate", {
									startTimeInMillis: this.$store.state.startTimeInMillis,
									endTimeInMillis: this.$store.state.endTimeInMillis,
								})
								.finally(() => {
									this.processingBooking = false
								})
						}
					})
			})
		},
		toggleBookingStatus() {
			this.processingBooking = true

			this.cancelError = null

			if (
				!this.bookingObject.item ||
				!this.bookingObject.item.uuid ||
				!this.bookingObject.customer ||
				!this.bookingObject.customer.uuid
			) {
				this.cancelError = this.$t(
					"unableToCancelBookingDueToMissingIdentifiers"
				)
				return
			}

			let status = false
			let subMessage = this.$t("successfullyCancelledBooking")
			if (this.bookingIsCancelled) {
				status = true
				subMessage = this.$t("successfullyReactivatedBooking")
			}

			const b = {
				uuid: this.bookingObject.uuid,
				item: this.bookingObject.item.uuid,
				customer: this.bookingObject.customer.uuid,
				namespace: this.bookingObject.namespace,
				status: status,
			}

			this.$store
				.dispatch("updateBooking", b)
				.then((result) => {
					if (result) {
						this.$store.commit("updateActionSuccess", {
							message: this.$t("success"),
							subMessage: subMessage,
						})
						this.$emit("close")
					} else {
						this.cancelError = this.$t("couldNotCancelBooking")
					}
				})
				.finally(() => {
					this.showConfirmCancel = false
					this.processingBooking = false
				})
		},
		saveComboBoxes() {
			/* For entering a custom value, the combobox will only create that new value after blur or enter.
			   When you click submit (the save/update button), this is firing at the same time that the select is being updated,
			   creating a race condition where the value isn't persisted in data before the form is processed.
			*/
			this.$refs.emailComboBox.blur()
			this.$refs.nameComboBox.blur()
			this.$refs.phoneComboBox.blur()
		},
		updateBooking() {
			this.saveComboBoxes()
			this.$nextTick(() => {
				// nextTick() must be used in order to ensure that the data saved via saveComboBoxes() is rendered in time to be
				// used to update the booking.
				this.processingBooking = true

				this.cancelError = null

				if (
					!this.bookingObject.item ||
					!this.bookingObject.item.uuid ||
					!this.bookingObject.customer ||
					!this.bookingObject.customer.uuid
				) {
					this.cancelError = this.$t(
						"unableToCancelBookingDueToMissingIdentifiers"
					)
					this.processingBooking = false
					return
				}

				const b = {
					uuid: this.bookingObject.uuid,
					item: this.selectedItem,
					customer: this.bookingObject.customer.uuid,
					namespace: "event",
					contactName: this.contactName ? this.contactName : this.$t("walkIn"),
					contactPhone: this.contactPhone,
					contactEmail: this.contactEmail,
					startsAtTime: Math.round(
						this.selectedTimeSlot.timestampInMillis / 1000
					),
					endsAtTime: Math.round(
						(this.selectedTimeSlot.timestampInMillis +
							this.selectedTimeSlot.paddingInMillis +
							1) /
							1000
					),
					location: this.selectedLocation,
					locationVariations:
						this.tables !== null && this.tables.length > 0
							? this.tables.map((t) => {
									return t + ""
							  })
							: null,
					notifyCustomer: this.sendNotification,
					internalNote: this.internalNote,
					externalNote: this.externalNote,
					quantity: this.quantity,
				}

				this.$store
					.dispatch("updateBooking", b)
					.then((result) => {
						if (result) {
							const uuid = result.uuid
							const item = result.item
							const itemTitle = item ? item.title : ''
							const bookingId = result.booking_id
							const customer = result.customer
							const customerName = (customer && customer.address && customer.address.name_line) ? customer.address.name_line : ''
							const startsAtTime = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(result.startsAtTime)).toISOString()
							const endsAtTime = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(result.endsAtTime)).toISOString()
							const quantity = result.quantity
							const locationLabel = this.selectedLocationObject ? this.selectedLocationObject.label : ''
							this.$store.commit("updateActionSuccess", {
								message: this.$t("successfullyUpdatedBooking"),
								subMessage: this.$t('bookingInfoText', {
									bookingId: bookingId,
									bookingUUID: uuid,
									customerName: customerName,
									quantity: quantity,
									itemTitle: itemTitle,
									locationLabel: locationLabel,
									dateFrom: startsAtTime.substring(0, 10),
									timeFrom: startsAtTime.substring(11, 16),
									dateTo: endsAtTime.substring(0, 10),
									timeTo: endsAtTime.substring(11, 16)
								}),
							})
							this.$emit("close")
						} else {
							this.$store.commit("updateActionError", {
								message: this.$t("errorOccurred"),
								subMessage: this.$t("unableToProcessBooking"),
							})
						}
					})
					.catch(() => {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToProcessBooking"),
						})
					})
					.finally(() => {
						this.processingBooking = false
					})
			})
		},
		rebookBooking() {
			return null
		},
		processBookingRedemption() {
			if (this.bookingObject && this.bookingObject.redeemedAt) {
				this.unRedeemBooking()
			} else {
				this.redeemBooking()
			}
		},
		redeemBooking() {
			this.processingBooking = true
			this.$store
				.dispatch("redeemBooking", this.bookingObject)
				.then((result) => {
					if (!result) {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToCheckinBookingPleaseTryAgain"),
						})
						this.processingBooking = false
						return
					}
					this.$emit("close")
				})
				.catch(() => {
					this.$store.commit("updateActionError", {
						message: this.$t("errorOccurred"),
						subMessage: this.$t("unableToCheckinBookingPleaseTryAgain"),
					})
				})
				.finally(() => {
					this.refreshBookings()
					this.processingBooking = false
				})
		},
		unRedeemBooking() {
			this.processingBooking = true
			this.$store
				.dispatch("unRedeemBooking", this.bookingObject)
				.then((result) => {
					if (!result) {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToCheckinBookingPleaseTryAgain"),
						})
						this.processingBooking = false
						return
					}
					this.$emit("close")
				})
				.catch(() => {
					this.$store.commit("updateActionError", {
						message: this.$t("errorOccurred"),
						subMessage: this.$t("unableToCheckinBookingPleaseTryAgain"),
					})
				})
				.finally(() => {
					this.refreshBookings()
					this.processingBooking = false
				})
		},
		refreshBookings() {
			if (this.selectedDate) {
				const dateMoment = moment(this.selectedDate)

				const payload = {
					startTimeInMillis: dateMoment.startOf("day").valueOf(),
					endTimeInMillis: dateMoment.endOf("day").valueOf(),
				}
				this.$store.dispatch("getBookingsByDate", payload)
			} else {
				this.$store.dispatch("getBookingsByDate", {
					startTimeInMillis: this.$store.state.startTimeInMillis,
					endTimeInMillis: this.$store.state.endTimeInMillis,
				})
			}
		},
		selectTime(value) {
			this.startDateAndTime = value
			const hours = value.split(":")[0]
			const minutes = value.split(":")[1]
			this.selectedDate = new Date(this.selectedDate.setHours(hours, minutes, 0, 0))
		},
		variationClick(variation) {
			if (this.tables !== null && this.tables.includes(variation.uuid)) {
				this.tables = this.tables.filter(
					(tableUUID) => tableUUID !== variation.uuid
				)
				return
			}

			this.tables.push(variation.uuid)
		},
		zoneHasVariations(zone) {
			return this.availableLocationVariations.find(
				(variation) => variation.zone.uuid === zone.uuid
			)
		},
		exportPDF: function () {
			let bookingStartDate = this.$moment(
				this.selectedTimeSlot.timestampInMillis
			).format("DD/MM/YYYY HH:mm")
			bookingStartDate += ` (${this.durationInMinutes} ${this.$t(
				"minutesLC"
			)})`
			let html = `<h1>${this.$t("booking")}: ${this.bookingStartDate}  </h1>`
			html += `<h2>${this.$t("quantity")}: ${this.quantity}</h2>`
			html += `<h3>${this.$t("name")}: ${this.contactName}</h3>`
			html += `<h3>${this.$t("phone")}: ${this.contactPhone}</h3>`
			html += `<h3>${this.$t("email")}: ${this.contactEmail}</h3>`
			if (this.externalNote !== undefined && this.externalNote !== null) {
				html += `<h2>${this.$t("externalNote")}</h2>`
				html += this.externalNote.replaceAll("\n", "<br/>")
			}
			if (this.internalNote !== undefined && this.internalNote !== null) {
				html += `<h2>${this.$t("internalNote")}</h2>`
				html += this.internalNote.replaceAll("\n", "<br/>")
			}

			this.$store.commit("exportPDF", {
				fileName: "Booking " + bookingStartDate,
				html: html,
			})
		},
		getCustomerBookings(customerUUID) {
			if (typeof customerUUID !== "undefined" && customerUUID !== null) {
				this.gettingBookingHistory = true

				this.$store
					.dispatch("getBookingsByCustomer", customerUUID)
					.then((bookings) => {
						this.customerBookings = bookings.sort(
							(a, b) => b.startsAtTime - a.startsAtTime
						)
					})
					.catch(() => {
						this.customerBookings = []
					})
					.finally(() => {
						this.gettingBookingHistory = false
						return this.customerBookings
					})
			}
		},
		getBookingHistory() {
			let customerUUID
			if (this.bookingObjectExists) {
				customerUUID = this.bookingObject.customer && this.bookingObject.customer.uuid ? this.bookingObject.customer.uuid : null
			} else if (this.bookingExists) {
				customerUUID = this.booking.customer && this.booking.customer.uuid ? this.booking.customer.uuid : null
			}

			if (typeof customerUUID !== "undefined" && customerUUID !== null) {
				return this.$store.dispatch('getBookingsByCustomer', customerUUID).then(result => {
					return result.sort((a, b) => b.startsAtTime - a.startsAtTime)
				})
			}
			return []
		},
		getOrderLineItems() {
			this.$store
				.dispatch("getOrderByUUID", { uuid: this.bookingObject.order })
				.then((result) => {
					if (
						typeof result !== "undefined" &&
						result !== null &&
						Array.isArray(result.lineItems)
					) {
						this.orderLineItems = result.lineItems
					}
				})
				.catch(() => {})
				.finally(() => {})
		},
		sendTicket() {
			this.$store
				.dispatch("sendTicket", this.bookingObject.uuid)
				.then((result) => {
					if (result) {
						this.$store.commit("updateActionSuccess", {
							message: this.$t("success"),
							subMessage: this.$t("ticketHasBeenSent"),
						})
					} else {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToSendTicket"),
						})
					}
				})
		},
		sendReservation() {
			this.$store
				.dispatch("sendReservation", this.bookingObject.uuid)
				.then((result) => {
					if (result) {
						this.$store.commit("updateActionSuccess", {
							message: this.$t("success"),
							subMessage: this.$t("reservationHasBeenSent"),
						})
					} else {
						this.$store.commit("updateActionError", {
							message: this.$t("errorOccurred"),
							subMessage: this.$t("unableToSendReservation"),
						})
					}
				})
		},
		filterName(value) {
			if(this.customerNameInputActive === false) {
				return
			}

			if (typeof value !== "undefined" && value !== null && value.length > 3) {
				const filteredCustomers = this.customers.filter((customer) => {
					if (
            customer &&
						customer.address &&
						customer.address.name_line &&
						customer.address.name_line.includes(value)
					) {
						return customer
					}
				})
				this.filteredCustomers = filteredCustomers
			} else {
				this.filteredCustomers = []
			}
		},
		filterPhone(value) {
			if(this.customerPhoneInputActive === false) {
				return
			}

			if (typeof value !== "undefined" && value !== null && value.length > 3) {
				const filteredCustomers = this.customers.filter((customer) => {
					if (customer && customer.phone && customer.phone.includes(value)) {
						return customer
					}
				})
				this.filteredCustomers = filteredCustomers
			} else {
				this.filteredCustomers = []
			}
		},
		filterEmail(value) {
			if(this.customerEmailInputActive === false) {
				return
			}

			if (typeof value !== "undefined" && value !== null && value.length > 3) {
				const filteredCustomers = this.customers.filter((customer) => {
					if (customer && customer.email && customer.email.includes(value)) {
						return customer
					}
				})
				this.filteredCustomers = filteredCustomers
			} else {
				this.filteredCustomers = []
			}
		},
	},
	mounted() {
		this.$store.dispatch('getAvailableBookingStates')
		if (this.bookingObjectExists) {
			this.getBookingHistory()
			this.getOrderLineItems()
		}

		if (typeof this.bookingObject !== "undefined" && this.bookingObject !== null) {
			if (
				typeof this.bookingObject.location !== "undefined" &&
				this.bookingObject.location !== null
			) {
				this.selectedLocation = this.bookingObject.location.uuid
			}

			// Making sure we update the quantity correctly
			if (
				typeof this.bookingObject.quantity !== "undefined" &&
				this.bookingObject.quantity !== null
			) {
				this.quantity = this.bookingObject.quantity
			}
		}

		if (Array.isArray(this.calendarLocationVariations) && this.calendarLocationVariations.length > 0) {
			for (
				let variationIndex = 0;
				variationIndex < this.calendarLocationVariations.length;
				variationIndex++
			) {
				const variation = this.calendarLocationVariations[variationIndex]

				if (!this.tables.includes(variation)) {
					this.tables.push(variation)
				}
			}
		}

		if(typeof this.bookingItem !== 'undefined' && this.bookingItem !== null) {
			this.selectedItem = this.bookingItem
		}

		this.onResize()
	},
	created() {
		if (this.walkIn) {
			this.internalNote = this.$t("walkInTable.")
		}

		// Handling if we have a booking object
		if (typeof this.bookingObject !== "undefined" && this.bookingObject !== null) {
			this.typeOfBooking = "event"
			if(Array.isArray(this.bookingObject.locationVariations) && this.bookingObject.locationVariations.length > 0) {
				this.typeOfBooking = 'reservation'
			}

			if(typeof this.bookingObject.internalNote !== "undefined" && this.bookingObject.internalNote !== null) {
				this.internalNote = this.bookingObject.internalNote
			}

			if(typeof this.bookingObject.externalNote !== "undefined" && this.bookingObject.externalNote !== null) {
				this.externalNote = this.bookingObject.externalNote
			}

			if(typeof this.bookingObject.quantity !== "undefined" && this.bookingObject.quantity !== null) {
				this.quantity = this.bookingObject.quantity
			}

			if(typeof this.bookingObject.item !== "undefined" && this.bookingObject.item !== null) {
				this.selectedItem = this.bookingObject.item.uuid
			}
		}
		// Handling if we have a booking item and no booking object
		else if (this.bookingItem !== null && this.bookingItem !== undefined) {
			this.typeOfBooking = "event"
			this.selectedItem = this.bookingItem
			this.e1++
		}

		if (typeof this.reservationTableItem !== 'undefined' && this.reservationTableItem !== null && (typeof this.bookingItem === 'undefined' || this.bookingItem === null)) {
			this.selectedItem = this.reservationTableItem.uuid
			this.typeOfBooking = "reservation"
		}
		// Handling if we have none above, but we have an array of event items
		else if(Array.isArray(this.items) && this.items.length > 0) {
			this.typeOfBooking = 'event'
			this.selectedItem = this.items[0].uuid
		}

		if (this.locations.length > 0 && this.selectedLocation === null) {
			this.selectedLocation = this.locations[0].uuid
		}

		// Quick fix so that we correctly display event items as event items
		// TODO: Fix better
		if (this.reservationTableItem && ((!this.bookingObject && !this.bookingItem) || (this.bookingObject && Array.isArray(this.bookingObject.locationVariations) && this.bookingObject.locationVariations.length > 0))) {
			this.typeOfBooking = 'reservation'
		} else {
			this.typeOfBooking = 'event'
		}

		// Very few are currently using table reservations, and we want to prioritise event selection when clicking Add booking
		this.typeOfBooking = 'event'

		if (
			this.selectedItemObject !== null &&
			this.selectedItemObject !== undefined &&
			this.selectedItemObject.defaultQuantity !== null &&
			this.selectedItemObject.defaultQuantity !== undefined
		) {
			this.quantity = this.selectedItemObject.defaultQuantity
		}

		if (
			this.selectedItemObject !== null &&
			this.selectedItemObject !== undefined &&
			this.selectedItemObject.defaultQuantity !== null &&
			this.selectedItemObject.defaultQuantity !== undefined
		) {
			this.durationInMinutes = this.selectedItemObject.durationInMinutes || 30
		}

		if (this.bookingDuration > 0) {
			this.durationInMinutes = this.bookingDuration
		}

		const selectedLocation = localStorage.getItem(
			this.organizationUUID + ":selectedLocation"
		)

		if (selectedLocation !== null && selectedLocation !== undefined) {
			this.selectedLocation = selectedLocation
		}

		if (this.bookingTime !== null && this.bookingTime !== undefined) {
			this.selectedDate = new Date(this.$store.getters.validateAndGetEpochTimestampInMillis(this.bookingTime))

			let dateNow = new Date(this.selectedDate)

			// In the case of default date 00:00, set the default to next available slot.
			if (dateNow.getHours() === 0 && dateNow.getMinutes() === 0) {
				const defaultDate = new Date()

				let hours = defaultDate.getHours()
				let minutes = defaultDate.getMinutes()

				if (minutes <= '15') {
					minutes = '15'
				}
				else if (minutes <= '30') {
					minutes = '30'
				}
				else if (minutes <= '45') {
					minutes = '45'
				}
				else if (minutes > '45') {
					defaultDate.addHours(1)
					hours = defaultDate.getHours()
					minutes = '00'
				}

				this.selectedDate.setHours(hours)
				this.selectedDate.setMinutes(Number(minutes))

				this.startDateAndTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`

				this.selectedTimeSlot = {
					timestampInMillis: defaultDate.getTime(),
					paddingInMillis: this.durationInMinutes * 60000 - 1,
				}

				return
			} 
			// This code is commented out because it causes issues with events happening at midnight (aka at 00:00)
			// if (
			// 	this.selectedDate.getHours() === 0 &&
			// 	this.selectedDate.getMinutes() === 0
			// ) {
			// 	dateNow = new Date(Date.now())
			// }

			let minutes = dateNow.getMinutes()
			if (!this.walkIn) {
				minutes =
				minutes < "15"
					? "00"
					: minutes < "30"
					? "15"
					: minutes < "45"
					? "30"
					: "45"
			}

			this.selectedDate.setHours(dateNow.getHours())
			this.selectedDate.setMinutes(Number(minutes))

			this.startDateAndTime =
				`${this.selectedDate.getHours().toString().padStart(2, "0")}:${this.selectedDate.getMinutes().toString().padStart(2, "0")}`

			this.selectedTimeSlot = {
				timestampInMillis: this.selectedDate.getTime(),
				paddingInMillis: this.durationInMinutes * 60000 - 1,
			}
		}
	},
}
</script>

<style>
.v-expansion-panel-content > .v-expansion-panel-content__wrap {
	padding: 0 !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
	> .v-expansion-panel--active {
	border-radius: 4px;
	margin-top: 0;
}

.icon-spin {
	animation: spin-animation 0.7s infinite;
	animation-timing-function: linear;
}

@keyframes spin-animation {
	0% {
		transform: rotate(0deg);
	}
	100%  {
		transform: rotate(-359deg);
	}
}
</style>
